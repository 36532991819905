<template>
  <v-card>
    <v-card-title  style='position: relative;'>Feedback
      <v-col
        cols="3"
        style='position: absolute;right: 0px;'
      >
        <div class="text-center">
          <v-btn
            color="primary"
            block
            @click="ExportData"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon> Export
          </v-btn>
        </div>
      </v-col>
    </v-card-title>
    <div>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @keyup="searchData"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="category"
              :items="categories"
              item-text="name"
              item-value="id"
              label="Category *"
              @change="categoryData"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="table-kitchen-sink"
      >
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {
	mdiDownload,
	mdiMagnify,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	name: 'FeedbackList',
	components: {},
	data() {
		return {
			headers: [
				{ text: 'NAME', value: 'name' },
				{ text: 'EMAIL', value: 'email' },
				{ text: 'MOBILE NUMBER', value: 'phone' },
				{ text: 'CATEGORY', value: 'feedback_category.name' },
				{ text: 'MESSAGE', value: 'message' },
				{ text: 'CREATED DATE', value: 'created_at' },
			],
			icons: { mdiMagnify, 				mdiDownload },
		}
	},
	computed: {
		...mapGetters('feedback', ['items', 'totalItems', 'categories']),
		options: {
			get() {
				return this.$store.getters['feedback/options']
			},
			set(value) {
				this.$store.commit('feedback/SET_OPTIONS', value)
			},
		},
		search: {
			get() {
				return this.$store.getters['feedback/search']
			},
			set(value) {
				this.$store.commit('feedback/SET_SEARCH', value)
			},
		},
		category: {
			get() {
				return this.$store.getters['feedback/category']
			},
			set(value) {
				this.$store.commit('feedback/SET_CATEGORY', value)
			},
		},
	},
	watch: {
		options() {
			this.datatable()
		},

	},
	mounted() {
		this.category_data()
	},
	methods: {
		searchData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		categoryData: _.debounce(function fn() {
			this.datatable()
		}, 400),
		datatable() {
			this.$store.dispatch('feedback/datatable')
		},
		category_data() {
			this.$store.dispatch('feedback/feedback_category')
		},
		ExportData: _.debounce(function fn() {
			this.exportdata()
		}, 400),
		exportdata() {
			this.$store.dispatch('feedback/export_data')
		},
	},
}
</script>

<style scoped>

</style>
